<template>
  <div v-if="currentQuestion !== null">
    <div class="question-header has-background-grey-lighter">
      <h6 class="title is-6 mb-2">Pregunta {{ currentQuestion+1 }}</h6>
      <h5 class="title is-5">{{ questions[currentQuestion]?.title }}</h5>
    </div>
    <div class="option-list section p-4" :class="{ disabled: optionVoted}">
      <b-button
        v-for="(option, index) in questions[currentQuestion].options"
        :key="option.id"
        class="option"
        :class="{voted: optionVoted == option.id}"
        @click="vote(option.id, index)">
          {{ option.name }}
      </b-button>
    </div>
  </div>
</template>

<script>
import './LivePollController.scss'

export default {
  components: {
    
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentQuestion: null,
      optionVoted: null
    }
  },
  computed: {
    questions() {
      return this.options.questions
    }
  },
  sockets: {
    currentState(data){
      this.currentQuestion = data.currentQuestion;
      this.optionVoted = data.existingVote

    },
    currentQuestion(data) {
      console.log('currentQuestion', data)
      this.currentQuestion = data.question
      this.optionVoted = null
    }
  },
  methods: {
    vote(optionId, index) {

      if(this.optionVoted) return
      this.optionVoted = optionId
      this.$socket.client.emit('gameMessage', {
        type: 'vote',
        voteIndex: index,
        voteId: optionId
      });
    }
  },
  mounted() {
   
  },
};
</script>
